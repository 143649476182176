import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WorkingComponent } from './working.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Daterangepicker } from 'ng2-daterangepicker';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PdfViewerModule,
    NgxSpinnerModule,
    Daterangepicker
  ],
  declarations: [WorkingComponent],
  exports: [
    WorkingComponent
  ],
  providers: [
  ]
})
export class WorkingModule { }

import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-warantyInsurance ',
  templateUrl: './warantyInsurance.component.html',
  styleUrls: ['./warantyInsurance.component.css']
})
export class WarantyInsuranceComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  } 
}
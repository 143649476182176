import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeaveComponent } from './leave.component';
import { QrCodeAllModule } from 'ngx-qrcode-all';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    QrCodeAllModule,
    NgxMatSelectSearchModule,
    MatSelectModule
  ],
  declarations: [LeaveComponent],
  exports: [
    LeaveComponent
  ],
  providers: [
  ]
})
export class LeaveModule { }

import { Component, OnInit ,ViewChild, ElementRef, NgZone} from '@angular/core';
import { MapsAPILoader } from '@agm/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployeeService, CustomerService, 
  MasterDataService, PermWorkRecordService, 
  CalendarHolidayService, CheckPointService } from '../../shared';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Bank, BANKS ,Customer, CUST} from '../../guard/permanent/guardCheckPoint/demo-data';
import { PreCheckPointService } from 'src/app/shared/service/preCheckPoint.service';  

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.css']
})
export class CheckinComponent implements OnInit {
  searchForm: FormGroup;
  openScan = false;
  title: string = 'AGM project';
  latitude!: number;
  longitude!: number;
  zoomLevel: number = 10;
  zoom: number = this.zoomLevel;
  address: string;
  private geoCoder;
  customerList = [];
  customerSearchId;
  dayList = [];
  type = '';
  qrCode = '';
  w_stutus = 'W';
  
  checkPointModel = {
    'peroid':'06-01-2022',
    'customerList':[]
  };
  emp;
  datas = [];
  checkInModel = null;
  dayNight = 'D';

  @ViewChild('search',{ static: true })
  public searchElementRef!: ElementRef;

  /** list of banks */
  protected customers: Customer[] = CUST;

  /** control for the selected bank */
  public custCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public custFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredCust: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private checkPointService: CheckPointService,
    private preCheckPointService: PreCheckPointService
  ) { 
    this.searchForm = fb.group({
      'month':1,
      'year': [new Date().getFullYear()],
      'customerId': [''],
    });
  }

  ngOnInit() { 
    this.emp = JSON.parse(localStorage.getItem('tisToken'));
    console.log(this.emp);
    this.getCheckInModel();
    this.initDayList();
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      this.getCustomer();
    });
    let now = new Date();
    console.log('house : ' + now.getHours());
    console.log('minute : ' + now.getMinutes()); 
  } 

  initDayList(){
    this.dayList = [];
    
    for(let i=1;i<=31;i++){
      this.dayList.push(i);
    }
    //console.log(this.checkPointModel);
  }

  getCheckInModel(){
    this.preCheckPointService.findByEmployeeId(this.emp.id).subscribe(res=>{
      console.log(res);
      if(res.length > 0){
        this.checkInModel = res[0];
      }else{
        this.checkInModel = null;
      } 
      console.log(this.checkInModel);
    });
  }

  getCustomer(){
    this.spinner.show();
    this.customerList = [];
    this.customerService.getCustomer2().subscribe(res=>{
      this.customerList = res;
      //console.log(this.customerList);
      this.searchCheckPoint();
      this.customers.push(
        {id:0,name:'เลือกหน่วยงาน'}
      )
      this.customerList.forEach(item=>{
        this.customers.push(
          {id:item.id,name:item.name}
        )
      });

      // set initial selection
    this.custCtrl.setValue(this.customers[0]);

    // load the initial bank list
    this.filteredCust.next(this.customers.slice());

    // listen for search field value changes
    this.custFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCust();
      });
      this.spinner.hide();
    });
  }

  protected filterCust() {
    if (!this.customers) {
      return;
    }
    if(this.custCtrl.value == null){return;}
    // get the search keyword
    console.log(this.custCtrl.value)
    this.customerSearchId = this.custCtrl.value['id'];
    let search = this.custFilterCtrl.value;
    console.log(this.customerSearchId)
    if (!search) {
      this.filteredCust.next(this.customers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCust.next(
      this.customers.filter(cust => cust.name.toLowerCase().indexOf(search) > -1)
    );
  }

  searchCheckPoint(){ 
    $("#checkpoint_table").DataTable().clear().destroy();
    this.datas = [];
    this.spinner.show(); 
    let criteria = {employee_id:this.emp.id};
    this.preCheckPointService.search(criteria).subscribe(res=>{
      this.spinner.hide(); 
      console.log(res);
      this.datas = res.data;
      this.datas.forEach(item => {
         item['process_status_display'] = this.getDisplayProcessStatus(item);
      });
      setTimeout(() => {
        $('#checkpoint_table').DataTable({
        });
      }, 10);
    });
  }

  getDisplayProcessStatus(value){
    if(value.process_status == 'W'){
      return 'รอระบบอัพเดทข้อมูล';
    }else if(value.process_status == 'P'){
      return 'อยู่ระหว่างอัพเดทข้อมูล';
    }else if(value.process_status == 'S'){
      return 'อัพเดทข้อมูลเรียบร้อย';
    }
  }

  savePreCheckPoint() { 
    let now = new Date();
    let hourse = now.getHours();
    let minute = now.getMinutes();
    console.log('house : ' + hourse);
    console.log('minute : ' + minute);
    let day = 0;
    let night = 0;
    let in_late = 0;
    if(this.type === 'I'){
      if(this.dayNight === 'D'){
        if((hourse === 7 && minute > 0) || (hourse > 7)){
          in_late = 1;
        }
      }else if(this.dayNight === 'N'){
        if((hourse === 19 && minute > 0) || (hourse > 19) || (hourse < 5)){
          in_late = 1;
        }
      }
      /*
      //Set ให้ check in ได้ก่อนเวลา 2 ชั่วโมง
      //Check Day
      if((hourse >= 5 && hourse <17)){
        day = 1;
        night = 0;
        if((hourse === 7 && minute > 0) || (hourse > 7)){
          in_late = 1;
        }
      }
      //Check Night
      if((hourse >= 17 || hourse <5)){
        day = 0;
        night = 1;
        if((hourse === 19 && minute > 0) || (hourse > 19) || (hourse < 5)){
          in_late = 1;
        }
      }*/
    }
    let out_late = 0;
    if(this.type === 'O'){
      if(this.checkInModel){
        if(this.checkInModel['day']){
          out_late = hourse>=19?0:1;
        }
        if(this.checkInModel['night']){
          out_late = (hourse>=7 && hourse<=19)?0:1;
        }
      }
    }

    this.spinner.show();
    let checkPoint = {
      type:this.type,
      customer_id: this.customerSearchId,
      employee_id: this.emp['id'],
      lat: this.latitude,
      lon: this.longitude,
      code: this.qrCode,
      w_status: this.w_stutus,
      //day: hourse >= 7 && hourse <=19 ? 1 : 0,
      //night: hourse > 19 || hourse <7 ? 1 : 0,
      day: this.dayNight==='D' ? 1 : 0,
      night: this.dayNight==='N' ? 1 : 0,
      in_late: in_late,
      out_late: out_late,
    };
    console.log(checkPoint);
    //return;
    this.preCheckPointService.createUpdateCheckPoint(checkPoint).subscribe(data => {
      $('#modal-confirm').modal('hide');
      //this.disabledSave = false;
      console.log(data)
      if(data.code === '00'){
        this.spinner.hide();
        this.successDialog();
        this.searchCheckPoint();
        this.getCheckInModel();
      }else{
        this.spinner.hide();
        console.error(data);
        this.failDialog(data.data);
      }
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog(error);
    });
  }

  testSave(){
    console.log('test save')
    console.log(this.customerList);
    let custs = this.customerList.filter(cust => cust.id == this.qrCode);
    console.log(custs[0]); 
    if(custs.length > 0){
      this.customerSearchId = custs[0].id;
    }else{
      this.customerSearchId = null;
    }
    if(this.customerSearchId == '0' || this.customerSearchId == null){
      this.warningDialog('ไม่พบ QR Code ในระบบ');
      return;
    } 
    let pointList = undefined;
    if(!custs[0]['point_list'] || custs[0]['point_list'] == null || custs[0]['point_list'] == ''){
      this.warningDialog('ไม่มีการตั้งค่าพื้นที่หน่วยงาน กรุณาติดต่อเจ้าหน้าที่');
      return;
    }else{
      pointList = JSON.parse(custs[0]['point_list']);
    }
    this.setCurrentLocation();
    let isInPolygon = this.isPointInPolygon({lat: this.latitude, lng: this.longitude}, pointList);
    console.log('isInPolygon', isInPolygon);
    if(!isInPolygon){
      this.warningDialog('ไม่อยู่ในพื้นที่');
      return;
    }
    console.log(this.emp)
    console.log('customer id : ' + this.customerSearchId);
    let date = new Date();
    let day = date.getDate();
    //day = 10;
    console.log('day : ' + day); 
    $('#modal-confirm').modal('show');
  }

  confirmSave(){
    this.savePreCheckPoint();
  }

  isPointInPolygon(point, polygonVertices) {
    var lat = point.lat;
    var lng = point.lng;
    var inside = false;
    var x = lng, y = lat;
    for (var i = 0, j = polygonVertices.length - 1; i < polygonVertices.length; j = i++) {
        var xi = polygonVertices[i].lng, yi = polygonVertices[i].lat;
        var xj = polygonVertices[j].lng, yj = polygonVertices[j].lat;

        var intersect = ((yi > y) != (yj > y)) &&
            (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
  }

  captureImage($event){
    console.log($event);
    this.qrCode = $event;
    this.openScan = !this.openScan;
    //alert($event);
    this.testSave();
  }

  inClicked(type){
    this.type = type;
    this.openScan = true;
  }

  onMapClicked(event: any){
    console.table(event.coords);
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  private setCurrentLocation() { 
    if ('geolocation' in navigator) {
      console.log('have geo location')
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        console.log(this.latitude)
        console.log(this.longitude)
        this.zoom = this.zoomLevel;
        this.getAddress(this.latitude, this.longitude); 
      });
    }else{
      console.log('not have geo location')
    }
  }
  
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      } 
    });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
    //setTimeout(() => {location.reload();}, 1000);
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'ไม่สามารถทำรายการได้',
      text: msg
    })
  }

  warningDialog(msg){
    Swal.fire({
      type: 'warning',
      title: 'ไม่สามารถทำรายการได้',
      text: msg
    })
  }

}

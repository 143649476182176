import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommingsoonComponent } from './comming-soon.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [CommingsoonComponent],
  exports: [
    CommingsoonComponent
  ],
  providers: [
  ]
})
export class CommingsoonModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AnnualLeaveComponent } from './annualLeave.component';
import { CommingsoonModule } from 'src/app/common-components/comming-soon/comming-soon.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommingsoonModule
  ],
  declarations: [AnnualLeaveComponent],
  exports: [
    AnnualLeaveComponent
  ],
  providers: [
  ]
})
export class AnnualLeaveModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { OutsiderComponent } from './outsider.component';
import {
  DepartmentService
} from '../shared';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [OutsiderComponent],
  exports: [
    OutsiderComponent
  ],
  providers: [
    DepartmentService
  ]
})
export class OutsiderModule { }

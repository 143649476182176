import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-annualLeave',
  templateUrl: './annualLeave.component.html',
  styleUrls: ['./annualLeave.component.css']
})
export class AnnualLeaveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  } 

}

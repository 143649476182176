import {Injectable} from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class Constant {

API_ENDPOINT :string;
API_REPORT_ENDPOINT :string;
API_DOMAIN :string;

constructor() {
  this.API_ENDPOINT = environment.api_endpoint;
  this.API_REPORT_ENDPOINT = environment.api_report_endpoint;
  this.API_DOMAIN = environment.api_domain;
  }
}
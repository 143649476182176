import { Component, Injectable, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-comming-soon',
  templateUrl: './comming-soon.component.html',
  styleUrls: ['./comming-soon.component.css']
})

@Injectable()
export class CommingsoonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  } 

}

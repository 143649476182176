import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-personalLeave',
  templateUrl: './personalLeave.component.html',
  styleUrls: ['./personalLeave.component.css']
})
export class PersonalLeaveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  } 

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PersonalLeaveComponent } from './personalLeave.component';
import { CommingsoonModule } from 'src/app/common-components/comming-soon/comming-soon.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommingsoonModule
  ],
  declarations: [PersonalLeaveComponent],
  exports: [
    PersonalLeaveComponent
  ],
  providers: [
  ]
})
export class PersonalLeaveModule { }

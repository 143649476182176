import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckinComponent } from './checkin.component';
import { QrCodeAllModule } from 'ngx-qrcode-all';
import { AgmCoreModule } from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material';
//import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
//import { ZXingScannerModule } from '@zxing/ngx-scanner';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    //ZXingScannerModule
    //NgxScannerQrcodeModule
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    QrCodeAllModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCJz79lL0YkOCZ0x9XixkFYDD0f7Ph2Vls',
      libraries: ['places']
    }),
    NgxMatSelectSearchModule,
    MatSelectModule
  ],
  declarations: [CheckinComponent],
  exports: [
    CheckinComponent
  ],
  providers: [
  ]
})
export class CheckinModule { }

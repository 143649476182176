import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var jQuery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { element } from 'protractor';
import { EmployeeService, CustomerService, 
  PasttimeWorkRecordService, CheckPointService,PermWorkRecordService, SlipService,Constant} from '../shared';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-checktransferpay',
  templateUrl: './checktransferpay.component.html',
  styleUrls: ['./checktransferpay.component.css']
})
export class CheckTransferPayComponent implements OnInit { 

  searchForm: FormGroup;
  startDate;
  startDateStr;
  endDate;
  endDateStr;
  customerList =  [];
  employeeList = [];
  checkPointList = [];
  options: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
    //singleDatePicker: true
  }; 
  optionSingleDate: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
    singleDatePicker: true
  }; 

  tisToken;
  customerId = 0;
  paidType = '';
  searchDateType = 'W';

  checkPointModel = {
    'peroid':'06-01-2022',
    'customerList':[]
  };
  //สำหรับ เสริม และควง
  extendList = [];
  //วันอาทิตย์
  sundayList = [];
  //pasttime
  pasttimeList = [];

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService,
    private customerService: CustomerService,
    private pasttimeWorkRecordService: PasttimeWorkRecordService,
    private checkPointService: CheckPointService,
    private spinner: NgxSpinnerService,
    private permWorkRecordService: PermWorkRecordService,
    private slipService: SlipService,
    private constant: Constant,
    ) { 
      this.tisToken = JSON.parse(localStorage.getItem('tisToken'));
      this.searchForm = fb.group({
        'startDate': [''],
        'endDate': [''],
        'customer_id': ['']
      });
   }

  ngOnInit() {
       this.startDate = new Date();
       this.startDate.setHours(0);
       this.startDate.setMinutes(0);
       this.startDate.setSeconds(0);
       this.startDateStr = this.getDateStr(new Date());

       this.endDate = new Date();
       this.endDate.setHours(0);
       this.endDate.setMinutes(0);
       this.endDate.setSeconds(0);
       //get customer
       this.getCustomer();
       this.getGuard();
       //get current date
       this.endDateStr = this.getDateStr(new Date());
       //auto search
       //this.search(this.searchForm.value);
  }

  getCustomer(){
    this.customerList = [];
    this.customerService.getCustomer().subscribe(res=>{
      this.customerList = res;
      //console.log(this.customerList);
    });
  }

  getGuard(){
    this.spinner.show();
    this.employeeList = [];
    this.permWorkRecordService.getGuard().subscribe(res=>{
      this.spinner.hide();
      this.employeeList = res;
      console.log(this.employeeList);
    });
  }

  search(){
    if(this.paidType != ''){
        this.extendList = [];
        this.sundayList = [];
        this.pasttimeList = [];

        console.log(this.paidType);
        //start date
        let sDay = this.startDate.getDate();
        console.log(this.startDateStr);
        console.log(sDay);
        
        //end date
        let eDay = this.endDate.getDate();
        console.log(this.endDateStr);
        console.log(eDay);
    
        console.log(this.customerId);
        this.checkPointList=[];
        //let searchParam = {};
        
        console.log(this.startDate.getMonth()+1);
        console.log(this.startDate.getFullYear());
        let checkPoint = {
          'month':this.startDate.getMonth()+1,
          'year':this.startDate.getFullYear(),
        };
        this.checkPointService.getCheckPoint(checkPoint).subscribe(data=>{
            console.log(data);
            if(data.data != null){
                this.checkPointModel = JSON.parse(data.data['json_data']);
                //ควง เสริม
                //let aaList = [];
                if(this.paidType == 'AA'){
                    for(let i=0;i<this.checkPointModel.customerList.length;i++){
                      console.log(this.checkPointModel.customerList[i])
                      for(let j=0;j<this.checkPointModel.customerList[i].employeeList.length;j++){
                            let aaWorkList = [];
                            for(let m=0;m<this.checkPointModel.customerList[i].employeeList[j].workList.length;m++){
                              let workItem = this.checkPointModel.customerList[i].employeeList[j].workList[m];
                              if(workItem.date >= sDay && workItem.date <= eDay){
                                if(workItem.status == 'A' 
                                || workItem.status == 'AA'
                                || workItem.status == 'AAD'
                                || workItem.status == 'AAN'
                                || workItem.status == 'AADN'){
                                  console.log(workItem)
                                  let wage = workItem.total;
                                  if(workItem.status == 'AAD'
                                  || workItem.status == 'AAN'
                                  || workItem.status == 'AADN'
                                  ){
                                    if(workItem.day && workItem.nigth){
                                      wage = workItem.total/2;
                                    }
                                  }
                                  if(workItem.status == 'A' || workItem.status == 'AA' || workItem.status == 'AADN'){
                                    if(workItem.day){
                                      aaWorkList.push(
                                        {
                                          'date':workItem.date,
                                          'workType':'D',
                                          'wage':wage,
                                          'paidStatus':workItem.paidStatus,
                                          'dataType':workItem.paidStatus == ''?'NEW':'OLD',
                                          'paidDate':workItem.paidDate
                                        });
                                    }
                                    if(workItem.nigth){
                                      aaWorkList.push(
                                        {
                                          'date':workItem.date,
                                          'workType':'N',
                                          'wage':wage,
                                          'paidStatus':workItem.paidStatus,
                                          'dataType':workItem.paidStatus == ''?'NEW':'OLD',
                                          'paidDate':workItem.paidDate
                                        });
                                    }
                                  }

                                  if(workItem.status == 'AAD'){
                                    if(workItem.day){
                                      aaWorkList.push(
                                        {
                                          'date':workItem.date,
                                          'workType':'D',
                                          'wage':wage,
                                          'paidStatus':workItem.paidStatus,
                                          'dataType':workItem.paidStatus == ''?'NEW':'OLD',
                                          'paidDate':workItem.paidDate
                                        });
                                    }
                                  }

                                  if(workItem.status == 'AAN'){
                                    if(workItem.nigth){
                                      aaWorkList.push(
                                        {
                                          'date':workItem.date,
                                          'workType':'N',
                                          'wage':wage,
                                          'paidStatus':workItem.paidStatus,
                                          'dataType':workItem.paidStatus == ''?'NEW':'OLD',
                                          'paidDate':workItem.paidDate
                                        });
                                    }
                                  }
                                  
                                }
                              }
                            }
                            if(aaWorkList.length>0){
                              let emps = this.employeeList.filter(emp => emp.id == this.checkPointModel.customerList[i].employeeList[j].employeeId);
                              this.extendList.push(
                                {
                                  'customerId':this.checkPointModel.customerList[i].customerId,
                                  'customerName':this.checkPointModel.customerList[i].customerName,
                                  'employeeId':this.checkPointModel.customerList[i].employeeList[j].employeeId,
                                  'employeeName':this.checkPointModel.customerList[i].employeeList[j].firstName
                                  +' '+this.checkPointModel.customerList[i].employeeList[j].lastName,
                                  'itemList':aaWorkList,
                                  'bankDetail':emps.length>0?emps[0].bank_account_no:'-',
                                  'totalPaid':0.00
                                }
                              );
                            }
                      }
                  }
                  console.log(this.extendList);
                } 
                //วันอาทิตย์
                else if(this.paidType == 'S'){
                  for(let i=0;i<this.checkPointModel.customerList.length;i++){
                    console.log(this.checkPointModel.customerList[i])
                    for(let j=0;j<this.checkPointModel.customerList[i].employeeList.length;j++){
                          let aaWorkList = [];
                          for(let m=0;m<this.checkPointModel.customerList[i].employeeList[j].workList.length;m++){
                            let workItem = this.checkPointModel.customerList[i].employeeList[j].workList[m];
                            if(workItem.date >= sDay && workItem.date <= eDay){
                              if(workItem.status == 'S'){
                                console.log(workItem)
                                if(workItem.day){
                                  aaWorkList.push(
                                    {
                                      'date':workItem.date,
                                      'workType':'D',
                                      'wage':workItem.total,
                                      'paidStatus':workItem.paidStatus,
                                      'dataType':workItem.paidStatus == ''?'NEW':'OLD',
                                      'paidDate':workItem.paidDate
                                    });
                                }
                                if(workItem.nigth){
                                  aaWorkList.push(
                                    {
                                      'date':workItem.date,
                                      'workType':'N',
                                      'wage':workItem.total,
                                      'paidStatus':workItem.paidStatus,
                                      'dataType':workItem.paidStatus == ''?'NEW':'OLD',
                                      'paidDate':workItem.paidDate
                                    });
                                }
                              }
                            }
                          }
                          if(aaWorkList.length>0){
                            let emps = this.employeeList.filter(emp => emp.id == this.checkPointModel.customerList[i].employeeList[j].employeeId);
                            this.sundayList.push(
                              {
                                'customerId':this.checkPointModel.customerList[i].customerId,
                                'customerName':this.checkPointModel.customerList[i].customerName,
                                'employeeId':this.checkPointModel.customerList[i].employeeList[j].employeeId,
                                'employeeName':this.checkPointModel.customerList[i].employeeList[j].firstName
                                +' '+this.checkPointModel.customerList[i].employeeList[j].lastName,
                                'itemList':aaWorkList,
                                'bankDetail':emps.length>0?emps[0].bank_account_no:'-',
                                'totalPaid':0.00
                              }
                            );
                          }
                    }
                }
                console.log(this.sundayList);
              }
                //past time
                else if(this.paidType == 'PA'){
                  for(let i=0;i<this.checkPointModel.customerList.length;i++){
                    console.log(this.checkPointModel.customerList[i])
                    for(let j=0;j<this.checkPointModel.customerList[i].employeeList.length;j++){
                          let aaWorkList = [];
                          for(let m=0;m<this.checkPointModel.customerList[i].employeeList[j].workList.length;m++){
                            let workItem = this.checkPointModel.customerList[i].employeeList[j].workList[m];
                            if(workItem.date >= sDay && workItem.date <= eDay){
                              if(workItem.status == 'PA'){
                                console.log(workItem)
                                if(workItem.day){
                                  aaWorkList.push(
                                    {
                                      'date':workItem.date,
                                      'workType':'D',
                                      'wage':workItem.total,
                                      'paidStatus':workItem.paidStatus,
                                      'dataType':workItem.paidStatus == ''?'NEW':'OLD',
                                      'paidDate':workItem.paidDate
                                    });
                                }
                                if(workItem.nigth){
                                  aaWorkList.push(
                                    {
                                      'date':workItem.date,
                                      'workType':'N',
                                      'wage':workItem.total,
                                      'paidStatus':workItem.paidStatus,
                                      'dataType':workItem.paidStatus == ''?'NEW':'OLD',
                                      'paidDate':workItem.paidDate
                                    });
                                }
                              }
                            }
                          }
                          if(aaWorkList.length>0){
                            let emps = this.employeeList.filter(emp => emp.id == this.checkPointModel.customerList[i].employeeList[j].employeeId);
                            this.pasttimeList.push(
                              {
                                'customerId':this.checkPointModel.customerList[i].customerId,
                                'customerName':this.checkPointModel.customerList[i].customerName,
                                'employeeId':this.checkPointModel.customerList[i].employeeList[j].employeeId,
                                'employeeName':this.checkPointModel.customerList[i].employeeList[j].firstName
                                +' '+this.checkPointModel.customerList[i].employeeList[j].lastName,
                                'itemList':aaWorkList,
                                'bankDetail':emps.length>0?emps[0].bank_account_no:'-',
                                'totalPaid':0.00
                              }
                            );
                          }
                    }
                }
                console.log(this.pasttimeList);
              }

            }
        });
    } 
  }
 
  trigCheckBoxWorkPaid(type,employeeId,customerId,paidType,date,$event){
    console.log($event.target.checked)
    console.log(type)
    console.log(employeeId)
    console.log(customerId)
    let paidDate = '';
    for(let i=0;i<this.checkPointModel.customerList.length;i++){
      if(this.checkPointModel.customerList[i].customerId == customerId){
        for(let j=0;j<this.checkPointModel.customerList[i].employeeList.length;j++){
          if(this.checkPointModel.customerList[i].employeeList[j].employeeId == employeeId){
            for(let k=0;k<this.checkPointModel.customerList[i].employeeList[j].workList.length;k++){
              if(this.checkPointModel.customerList[i].employeeList[j].workList[k].date == date){
                if($event.target.checked){
                  this.checkPointModel.customerList[i].employeeList[j].workList[k].paidStatus = 'P';
                  if(this.checkPointModel.customerList[i].employeeList[j].workList[k].paidDate == ''
                  || this.checkPointModel.customerList[i].employeeList[j].workList[k].paidDate == null){
                     this.checkPointModel.customerList[i].employeeList[j].workList[k].paidDate = this.getDateStr(new Date());
                  }
                  paidDate = this.checkPointModel.customerList[i].employeeList[j].workList[k].paidDate;
                }else{
                  this.checkPointModel.customerList[i].employeeList[j].workList[k].paidStatus = '';
                  this.checkPointModel.customerList[i].employeeList[j].workList[k].paidDate = '';
                  paidDate = '';
                }
                console.log(this.checkPointModel.customerList[i].employeeList[j].workList[k]);
              }
            } 
          }
        }
      }
    }
    if(paidType == 'AA'){
      for(let i=0;i<this.extendList.length;i++){
        if(this.extendList[i].customerId == customerId && this.extendList[i].employeeId == employeeId){
          for(let j=0;j<this.extendList[i].itemList.length;j++){
            if(this.extendList[i].itemList[j].date == date){
              if($event.target.checked){
                this.extendList[i].itemList[j].paidStatus = 'P';
                this.extendList[i].totalPaid = this.extendList[i].totalPaid + this.extendList[i].itemList[j].wage;
              }else{
                this.extendList[i].itemList[j].paidStatus = '';
                this.extendList[i].totalPaid = this.extendList[i].totalPaid - this.extendList[i].itemList[j].wage;
              }
              this.extendList[i].itemList[j].paidDate = paidDate;
            }
          }
        }
      }
    }else if(paidType == 'S'){
      for(let i=0;i<this.sundayList.length;i++){
        if(this.sundayList[i].customerId == customerId && this.sundayList[i].employeeId == employeeId){
          for(let j=0;j<this.sundayList[i].itemList.length;j++){
            if(this.sundayList[i].itemList[j].date == date){
              if($event.target.checked){
                this.sundayList[i].itemList[j].paidStatus = 'P';
                this.sundayList[i].totalPaid = this.sundayList[i].totalPaid + this.sundayList[i].itemList[j].wage;
              }else{
                this.sundayList[i].itemList[j].paidStatus = '';
                this.sundayList[i].totalPaid = this.sundayList[i].totalPaid - this.sundayList[i].itemList[j].wage;
              }
              this.sundayList[i].itemList[j].paidDate = paidDate;
            }
          }
        }
      }
    }else if(paidType == 'PA'){
      for(let i=0;i<this.pasttimeList.length;i++){
        if(this.pasttimeList[i].customerId == customerId && this.pasttimeList[i].employeeId == employeeId){
          for(let j=0;j<this.pasttimeList[i].itemList.length;j++){
            if(this.pasttimeList[i].itemList[j].date == date){
              if($event.target.checked){
                this.pasttimeList[i].itemList[j].paidStatus = 'P';
                this.pasttimeList[i].totalPaid = this.pasttimeList[i].totalPaid + this.pasttimeList[i].itemList[j].wage;
              }else{
                this.pasttimeList[i].itemList[j].paidStatus = '';
                this.pasttimeList[i].totalPaid = this.pasttimeList[i].totalPaid - this.pasttimeList[i].itemList[j].wage;
              }
              this.pasttimeList[i].itemList[j].paidDate = paidDate;
            }
          }
        }
      }
    }
  }


  updateCheckPoint(){
    //console.log(this.searchForm.get('month').value)
    let checkPoint = {
      'month':this.startDate.getMonth()+1,
      'year':this.startDate.getFullYear(),
      'json_data':JSON.stringify(this.checkPointModel),
    };
    console.log(checkPoint);
    this.spinner.show();
    this.checkPointService.createUpdateCheckPoint(checkPoint).subscribe(data => {
      console.log(data)
      this.spinner.hide();
      this.successDialog();
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog(error);
    });
  }

  printCashSlip(){
    let title = '';
    let list = [];
    if(this.paidType == 'AA' || this.paidType == 'A'){
      title = 'ประเภทการจ่าย : เสริม / ควง';
      list = this.extendList;
    }else if(this.paidType == 'S'){
      title = 'ประเภทการจ่าย : วันอาทิตย์';
      list = this.sundayList;
    }else if(this.paidType == 'PA'){
      title = 'ประเภทการจ่าย : พาร์สทาม';
      list = this.pasttimeList;
    }

    let startDate = this.startDateStr;
    let endDate = this.endDateStr;
    let request = {
      'title':title,
      'startDate':startDate,
      'endDate':endDate,
      'detail':{'list':list}
    }
    this.slipService.cashSlip(request).subscribe(res=>{
      console.log(res)
      console.log(res.data);
      window.open(this.constant.API_ENDPOINT + "/slip/download/cash");
      /*
      console.log(res);
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');*/
    });
  }

  selectedDate(value: any, datepicker?: any) {
    this.startDate = value.start._d;
    this.startDateStr = this.getDateStr(this.startDate);
    console.log(this.startDateStr);
    this.endDate = value.end._d;
    this.endDateStr = this.getDateStr(this.endDate);
    console.log(this.endDateStr);
  }

  selectedDatePaid(type,employeeId,customerId,paidType,date,value: any, datepicker?: any) {
    let startDate = value.start._d;
    let startDateStr = this.getDateStr(startDate);
    console.log(startDateStr);
    console.log(employeeId)
    console.log(customerId)
    for(let i=0;i<this.checkPointModel.customerList.length;i++){
      if(this.checkPointModel.customerList[i].customerId == customerId){
        for(let j=0;j<this.checkPointModel.customerList[i].employeeList.length;j++){
          if(this.checkPointModel.customerList[i].employeeList[j].employeeId == employeeId){
            for(let k=0;k<this.checkPointModel.customerList[i].employeeList[j].workList.length;k++){
              if(this.checkPointModel.customerList[i].employeeList[j].workList[k].date == date){
                  //this.checkPointModel.customerList[i].employeeList[j].workList[k].paidStatus = 'P';
                  this.checkPointModel.customerList[i].employeeList[j].workList[k].paidDate = startDateStr;
                  console.log(this.checkPointModel.customerList[i].employeeList[j].workList[k]);
              }
            } 
          }
        }
      }
    }
  }

  searchDateTypeSeleted(type){
    this.searchDateType = type;
    console.log(this.searchDateType);
  }

  getDateStr(date){
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    return dd + '-' + mm + '-' + yyyy;
  }
 
  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
    setTimeout(() => {location.reload();}, 1000);
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CheckPointService, Constant, PermWorkRecordService, SlipService } from 'src/app/shared';
import { AdvMoneyService } from 'src/app/shared/service/advMoney.service';
declare var jquery: any;
declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-working',
  templateUrl: './working.component.html',
  styleUrls: ['./working.component.css']
})
export class WorkingComponent implements OnInit {

  startDate;
  startDateStr;
  endDate;
  endDateStr;
  options: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
    singleDatePicker: false
  };


  emp;
  searchForm: FormGroup;
  addForm: FormGroup;
  yearList;
  currentYear;
  dayList = [];
  customerList =  [];
  employeeList = [];
  peroidType = 0;
  peroidText = '';
  peroidMonth = 0;
  workViewList = [];
  yearSeach:number = 0;
  empPrint;
  isSearch = false;

  checkPointModel = {
    'peroid':'06-01-2022',
    'customerList':[]
  };
  
  checkPointModelCompare = {
    'peroid':'06-01-2022',
    'customerList':[]
  };
  checkPointRecheck = [];
  advList = [];

  constructor(
    private fb: FormBuilder,
    private permWorkRecordService: PermWorkRecordService,
    private checkPointService: CheckPointService,
    private spinner: NgxSpinnerService,
    private slipService: SlipService,
    private constant: Constant,
    private advMoneyService: AdvMoneyService
  ) { 
    this.searchForm = fb.group({
      'month':'1-0',
      'year': [new Date().getFullYear()],
      'customerId': [''],
    });
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.generateYearList();
    //this.getGuard();
    this.emp = JSON.parse(localStorage.getItem('tisToken'));
    this.getAdvByEmployeeIdV2(this.emp.id);
  } 


  getAdvByEmployeeIdV2(empId){
    this.advList = [];
    this.advMoneyService.getByEmployeeByIdV2(empId).subscribe(res=>{
      this.advList = res;
      console.log(this.advList);
    });
  }

  generateYearList(){
    this.yearList = [];
    let currentYear = new Date().getFullYear();
    console.log('currentYear : ' + currentYear);
    //generate next 3 year
    for(let i=0;i<5;i++){
      let year = 
      {
        'enYear':currentYear-i,
        'thYear':(currentYear-i) + 543
      };
      this.yearList.push(year);
    }
  } 

  getGuard(){
    this.employeeList = [];
    this.permWorkRecordService.getGuard().subscribe(res=>{
      this.employeeList = res;
      //console.log(this.employeeList);
    });
  }

  monthSearch;
  yearSearch;
  searchCheckPoint(){
    this.isSearch = true;
    this.spinner.show();
    this.empPrint = null;
    this.checkPointModel = {
      'peroid':'06-01-2022',
      'customerList':[]
    };

    //New Date
    let splitStartDate = this.startDateStr.split('-');
    let splitEndDate = this.endDateStr.split('-');
    this.monthSearch = Number(splitStartDate[1]);
    this.yearSearch = Number(splitStartDate[2]);

    this.checkPointRecheck = [];
    this.dayList = [];
    this.workViewList = [];

    this.yearSeach = Number(this.searchForm.get('year').value) + 543;
    let splitMonth = this.searchForm.get('month').value.split('-');
    this.peroidMonth = splitMonth[0];
    this.peroidType = splitMonth[1];
    let dateStart = 1;
    let dateEnd = 15;
    if(this.peroidType == 1){
      dateStart = 16;
      if(this.peroidMonth == 2){
        dateEnd = 28;
      }else if(this.peroidMonth == 4 || this.peroidMonth == 6 
        || this.peroidMonth == 9 || this.peroidMonth == 11){
          dateEnd = 30;
      }else{
        dateEnd = 31;
      } 
    }

    //set new start date end  date
    dateStart = Number(splitStartDate[0])
    dateEnd = Number(splitEndDate[0])

    for(let i=dateStart;i<=dateEnd;i++){
      this.dayList.push(i);
    }
    let month = splitMonth[0];
    let checkPoint = {
      //'month':month,
      //'year':this.searchForm.get('year').value
      'month':Number(splitStartDate[1]),
      'year':Number(splitStartDate[2])
    };
    console.log(checkPoint)
    this.checkPointService.getCheckPoint(checkPoint).subscribe(data=>{
      //console.log(data);
      if(data.data != null){
        this.checkPointModel = JSON.parse(data.data['json_data']);
        this.checkPointModelCompare = this.checkPointModel;
        console.log(this.checkPointModel);
        this.checkPointRecheck.push(this.emp);

        for(let k=0;k<this.checkPointRecheck.length;k++){
          let customerWorkList = [];
          for(let i=0;i<this.checkPointModel.customerList.length;i++){
            for(let j=0;j<this.checkPointModel.customerList[i].employeeList.length;j++){
              if(this.checkPointModel.customerList[i].employeeList[j].employeeId == this.checkPointRecheck[k].id){
                console.log(this.checkPointModel.customerList[i].employeeList[j])
                if(this.empPrint==null){
                  this.empPrint = this.checkPointModel.customerList[i].employeeList[j];
                } 
                let workList = [];
                for(let m=0;m<this.checkPointModel.customerList[i].employeeList[j].workList.length;m++){
                  let workItem = this.checkPointModel.customerList[i].employeeList[j].workList[m];
                  if(workItem.date >= dateStart && workItem.date <= dateEnd){
                    workList.push(workItem);
                  }
                }
                let obj = {
                  'customerId':this.checkPointModel.customerList[i].customerId,
                  'customerName':this.checkPointModel.customerList[i].customerName,
                  'workList':workList
                }
                customerWorkList.push(obj);
              }
            }
          }
          this.checkPointRecheck[k]['customerWorkList'] = customerWorkList;
        }
        console.log(this.checkPointRecheck);
        //work view list
        this.dayList.forEach(item => {
          let day = {'day':item,cust:[]}
          for(let i=0;i<this.checkPointRecheck[0].customerWorkList.length;i++){
            for(let j=0;j<this.checkPointRecheck[0].customerWorkList[i].workList.length;j++){
              let work = this.checkPointRecheck[0].customerWorkList[i].workList[j];
              if(work.date == item && work.status != ''){
                day.cust.push(
                  {
                    'customerName': this.checkPointRecheck[0].customerWorkList[i].customerName,
                    'work':work
                  }
                );
              }
            }
          }
          this.workViewList.push(day);
        });

        console.log(this.workViewList);
        this.spinner.hide();
      }else{
        this.spinner.hide();
      }
    });
  }

  printMonthSlip(status) {
    this.spinner.show();
    console.log(this.checkPointModel) 
    //New Date
    let splitStartDate = this.startDateStr.split('-');
    let splitEndDate = this.endDateStr.split('-');
    this.monthSearch = Number(splitStartDate[1]);
    this.yearSearch = Number(splitStartDate[2]);

    //set new start date end  date
    let dateStart = Number(splitStartDate[0])
    let dateEnd = Number(splitEndDate[0])

    console.log('dateStart',dateStart)
    console.log('dateEnd',dateEnd)
    
    for (let i = 0; i < this.checkPointModel.customerList.length; i++) {
      //if (this.checkPointModel.customerList[i].customerId == customerId) {
        for (let j = 0; j < this.checkPointModel.customerList[i].employeeList.length; j++) {
          if (this.checkPointModel.customerList[i].employeeList[j].employeeId == this.emp.id) {
            //check การทำงานช่วย
            let isHH = false;
            for(let m=0;m<this.checkPointModel.customerList[i].employeeList[j].workList.length;m++){
              let workItem = this.checkPointModel.customerList[i].employeeList[j].workList[m];
                if(workItem.date >= dateStart && workItem.date <= dateEnd){
                  if(workItem.status == 'HE'){
                    isHH = true;
                  }
                }
             }

             if(!isHH){
                let peroid = '';
                /*if(this.peroidType == 0){
                  peroid = peroid + '1-15 ';
                }else if(this.peroidType == 1){
                  peroid = peroid + '16-30 ';
                }*/
                console.log(peroid);
                peroid = peroid + dateStart.toString().padStart(2, '0') 
                + '-' + dateEnd.toString().padStart(2, '0') + ' ';
                if (this.monthSearch == 1) {
                  peroid = peroid + 'มกราคม ';
                } else if (this.monthSearch == 2) {
                  peroid = peroid + 'กุมภาพันธ์ ';
                } else if (this.monthSearch == 3) {
                  peroid = peroid + 'มีนาคม ';
                } else if (this.monthSearch == 4) {
                  peroid = peroid + 'เมษายน ';
                } else if (this.monthSearch == 5) {
                  peroid = peroid + 'พฤษภาคม ';
                } else if (this.monthSearch == 6) {
                  peroid = peroid + 'มิถุนายน ';
                } else if (this.monthSearch == 7) {
                  peroid = peroid + 'กรกฎาคม ';
                } else if (this.monthSearch == 8) {
                  peroid = peroid + 'สิงหาคม ';
                } else if (this.monthSearch == 9) {
                  peroid = peroid + 'กันยายน ';
                } else if (this.monthSearch == 10) {
                  peroid = peroid + 'ตุลาคม ';
                } else if (this.monthSearch == 11) {
                  peroid = peroid + 'พฤศจิกายน ';
                } else if (this.monthSearch == 12) {
                  peroid = peroid + 'ธันวาคม ';
                }
                peroid = peroid + this.yearSeach;
                
                let deductExtendList002 = [];
                let deductExtendList003 = [];
                let deductExtendList004 = [];
                let deductExtendList005 = [];
                if(this.checkPointModel.customerList[i].employeeList[j]['deductList']){
                   
                for (let x = 0; x < this.checkPointModel.customerList[i].employeeList[j]['deductList'].length; x++) {
                  if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].code == '002') {
                    if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList) {
                      deductExtendList002 = this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList;
                    }
                  }
                } 
                for (let x = 0; x < this.checkPointModel.customerList[i].employeeList[j]['deductList'].length; x++) {
                  if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].code == '003') {
                    if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList) {
                      deductExtendList003 = this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList;
                    }
                  }
                }
     
                for (let x = 0; x < this.checkPointModel.customerList[i].employeeList[j]['deductList'].length; x++) {
                  if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].code == '004') {
                    if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList) {
                      deductExtendList004 = this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList;
                    }
                  }
                }
     
                for (let x = 0; x < this.checkPointModel.customerList[i].employeeList[j]['deductList'].length; x++) {
                  if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].code == '005') {
                    if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList) {
                      deductExtendList005 = this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList;
                    }
                  }
                }
                } 
    
                let countDay = 0;
                let priceDay = 0.00;
                let totalDay = 0.00;
                let countNigth = 0;
                let priceNigth = 0.00;
                let totalNigth = 0.00;
                let workList = [];
                let sumSociety = 0.00;
                /*let dateStart = 1;
                let dateEnd = 15;
                if (this.peroidType == 1) {
                  dateStart = 16;
                  dateEnd = 31;
                }*/
                for (let m = 0; m < this.checkPointModel.customerList[i].employeeList[j].workList.length; m++) {
                  let workItem = this.checkPointModel.customerList[i].employeeList[j].workList[m];
                  if (workItem.date >= dateStart && workItem.date <= dateEnd) {
                    if(workItem.status == 'W' || workItem.status == 'AAD' || workItem.status == 'AAN'){
                      workList.push(workItem);

                      if(workItem.status == 'W'){
                        if(workItem.day){
                          countDay++;
                          priceDay = Number(workItem.waged);
                          totalDay = totalDay + priceDay;
                          sumSociety = sumSociety + ((priceDay/12)*8);
                        }
                        if(workItem.nigth){
                          countNigth++;
                          priceNigth = Number(workItem.wagen);
                          totalNigth = totalNigth + priceNigth;
                          sumSociety = sumSociety + ((priceNigth/12)*8);
                        }
                      }
                      if(workItem.status == 'AAD'){
                        if(workItem.nigth){
                          countNigth++;
                          priceNigth = Number(workItem.wagen);
                          totalNigth = totalNigth + priceNigth;
                          sumSociety = sumSociety + ((priceNigth/12)*8);
                        }
                      }
                      if(workItem.status == 'AAN'){
                        if(workItem.day){
                          countDay++;
                          priceDay = Number(workItem.waged);
                          totalDay = totalDay + priceDay;
                          sumSociety = sumSociety + ((priceDay/12)*8);
                        }
                      }

                    } 
                  }
                }
                //get status HE ช่วย
               let countDayHh = 0;
               let priceDayHh = 0.00;
               let totalDayHh = 0.00;
               let countNigthHh = 0;
               let priceNigthHh = 0.00;
               let totalNigthHh = 0.00;
                let heList = [];
                for (let ii = 0; ii < this.checkPointModelCompare.customerList.length; ii++) {
                  for (let jj = 0; jj < this.checkPointModelCompare.customerList[ii].employeeList.length; jj++) {
                    if (this.emp.id == this.checkPointModelCompare.customerList[ii].employeeList[jj].employeeId) {
                      for (let mm = 0; mm < this.checkPointModelCompare.customerList[ii].employeeList[jj].workList.length; mm++) {
                        let workItem = this.checkPointModelCompare.customerList[ii].employeeList[jj].workList[mm];
                        if (workItem.date >= dateStart && workItem.date <= dateEnd) {
                          if (workItem.status == 'HE') {
                            heList.push(workItem);
                            if(workItem.day){
                              countDayHh++;
                              priceDayHh = Number(workItem.waged);
                              totalDayHh = totalDayHh + priceDayHh;
                              sumSociety = sumSociety + ((priceDayHh / 12) * 8);
                            }
                            if(workItem.nigth){
                              countNigthHh++;
                              priceNigthHh = Number(workItem.wagen);
                              totalNigthHh = totalNigthHh + priceNigthHh;
                              sumSociety = sumSociety + ((priceNigthHh / 12) * 8);
                            }
                          }
                        }
                      }
                    }
                  }
                }
                console.log(heList)
                for (let ii = 0; ii < workList.length; ii++) {
                  if (workList[ii].status != 'W' || (workList[ii].status == 'W' && !workList[ii].day && !workList[ii].nigth)) {
                    let workMatch = heList.filter(he => he.date == workList[ii].date);
                    if (workMatch.length > 0) {
                      workList[ii] = workMatch[0];
                    }
                  }
                }  

                if(this.checkPointModel.customerList[i].employeeList[j]['incomeList']){
                  for (let x = 0; x < this.checkPointModel.customerList[i].employeeList[j]['incomeList'].length; x++) {
                    if (this.checkPointModel.customerList[i].employeeList[j]['incomeList'][x].code == '001') {
                      this.checkPointModel.customerList[i].employeeList[j]['incomeList'][x]
                      = {'code':'001','name':'ค่าแรง(D)','quantity':countDay,'price':priceDay,'total':totalDay,'editType':0,'incomeType':'FIX'};
                    }
                    if (this.checkPointModel.customerList[i].employeeList[j]['incomeList'][x].code == '002') {
                      this.checkPointModel.customerList[i].employeeList[j]['incomeList'][x]
                      = {'code':'002','name':'ค่าแรง(N)','quantity':countNigth,'price':priceNigth,'total':totalNigth,'editType':0,'incomeType':'FIX'};
                    }
                  }
                }

                let societyPercent = 0.05;
                let summarySociety = Math.ceil((sumSociety) * (societyPercent));
                let societyPercentText = '5%';
                if(this.checkPointModel.customerList[i].employeeList[j]['deductList']){
                  for(let x=0;x<this.checkPointModel.customerList[i].employeeList[j]['deductList'].length;x++){
                    if(this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].code == '001'){
                        this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].price = summarySociety;
                        this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].total = summarySociety;
                        this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].editType = 1;
                        this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].incomeType = 'MANUAL';
                        this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].name = 'ประกันสังคม('+societyPercentText+')';
                    }
                  }
                }  

                console.log(workList)
                if(workList.length>0){
                  let currentYear = new Date().getFullYear();
                  let request = {
                    "customerName": this.checkPointModel.customerList[i].customerName,
                    "employeeName": this.checkPointModel.customerList[i].employeeList[j].firstName
                      + " " + this.checkPointModel.customerList[i].employeeList[j].lastName,
                    "incomeList": this.checkPointModel.customerList[i].employeeList[j].incomeList,
                    "deductList": this.checkPointModel.customerList[i].employeeList[j].deductList,
                    "totalIncome": this.checkPointModel.customerList[i].employeeList[j].totalIncome,
                    "totalDeduct": this.checkPointModel.customerList[i].employeeList[j].totalDeduct,
                    "peroid": peroid,
                    "deductExtendList002": deductExtendList002,
                    "deductExtendList003": deductExtendList003,
                    "deductExtendList004": deductExtendList004,
                    "deductExtendList005": deductExtendList005,
                    "workList": workList,
                    "employeeId": this.checkPointModel.customerList[i].employeeList[j].employeeId
                    //"employeeId": this.checkPointModel.customerList[i].employeeList[j].employeeId
                    //+"_"+this.peroidType+"_"+this.peroidMonth+"_"+currentYear
                  };
                  console.log(request);
                  const parentThis = this;
                  if(status == 'view'){
                    this.slipService.cashSlipImageMonthly(request).subscribe(res => {
                      console.log(res)
                      console.log(res.data);
                      /*let printUrl = this.constant.API_ENDPOINT 
                      + "/slip/download/monthly/"+this.checkPointModel.customerList[i].employeeList[j].employeeId
                      +"_"+this.peroidType+"_"+this.peroidMonth+"_"+currentYear;*/
                      let printUrl = this.constant.API_ENDPOINT
                        + "/slip/download/monthly/" + this.checkPointModel.customerList[i].employeeList[j].employeeId;
                      // setTimeout(function() { 
                      //   parentThis.spinner.hide();
                      //   window.open(printUrl);
                      // }, 30000); 
                      parentThis.spinner.hide();
                      window.open(res.data);
                    }, error => {
                      parentThis.spinner.hide();
                      console.error(error);
                      this.failDialog('');
                    });
                  }else{
                    this.slipService.cashSlipMonthly(request).subscribe(res => {
                      console.log(res)
                      console.log(res.data);
                      /*let printUrl = this.constant.API_ENDPOINT 
                      + "/slip/download/monthly/"+this.checkPointModel.customerList[i].employeeList[j].employeeId
                      +"_"+this.peroidType+"_"+this.peroidMonth+"_"+currentYear;*/
                      let printUrl = this.constant.API_ENDPOINT
                        + "/slip/download/monthly/" + this.checkPointModel.customerList[i].employeeList[j].employeeId;
                      // setTimeout(function() { 
                      //   parentThis.spinner.hide();
                      //   window.open(printUrl);
                      // }, 30000); 
                      parentThis.spinner.hide();
                      window.open(res.data);
                    }, error => {
                      parentThis.spinner.hide();
                      console.error(error);
                      this.failDialog('');
                    });
                  }
              }
             } 
          }//End loop Check Employee
        }
      //}
    }
  }

  printMonthSlip_2(status) {
    this.spinner.show();
    console.log(this.checkPointModel)
    let splitMonth = this.searchForm.get('month').value.split('-');
    this.peroidMonth = splitMonth[0];
    this.peroidType = splitMonth[1];
    var dateStart = 1;
    let dateEnd = 15;
    if(this.peroidType == 1){
      dateStart = 16;
      dateEnd = 31;
    }
    let month = splitMonth[0];
    
    for (let i = 0; i < this.checkPointModel.customerList.length; i++) {
      //if (this.checkPointModel.customerList[i].customerId == customerId) {
        for (let j = 0; j < this.checkPointModel.customerList[i].employeeList.length; j++) {
          if (this.checkPointModel.customerList[i].employeeList[j].employeeId == this.emp.id) {
            //check การทำงานช่วย
            let isHH = false;
            for(let m=0;m<this.checkPointModel.customerList[i].employeeList[j].workList.length;m++){
              let workItem = this.checkPointModel.customerList[i].employeeList[j].workList[m];
                if(workItem.date >= dateStart && workItem.date <= dateEnd){
                  if(workItem.status == 'HE'){
                    isHH = true;
                  }
                }
             }

             if(!isHH){
                let peroid = '';
                /*if(this.peroidType == 0){
                  peroid = peroid + '1-15 ';
                }else if(this.peroidType == 1){
                  peroid = peroid + '16-30 ';
                }*/
                if (this.peroidMonth == 1) {
                  if (this.peroidType == 0) {
                    peroid = peroid + '1-15 ';
                  } else if (this.peroidType == 1) {
                    peroid = peroid + '16-31 ';
                  }
                  peroid = peroid + 'มกราคม ';
                } else if (this.peroidMonth == 2) {
                  if (this.peroidType == 0) {
                    peroid = peroid + '1-15 ';
                  } else if (this.peroidType == 1) {
                    peroid = peroid + '16-29 ';
                  }
                  peroid = peroid + 'กุมภาพันธ์ ';
                } else if (this.peroidMonth == 3) {
                  if (this.peroidType == 0) {
                    peroid = peroid + '1-15 ';
                  } else if (this.peroidType == 1) {
                    peroid = peroid + '16-31 ';
                  }
                  peroid = peroid + 'มีนาคม ';
                } else if (this.peroidMonth == 4) {
                  if (this.peroidType == 0) {
                    peroid = peroid + '1-15 ';
                  } else if (this.peroidType == 1) {
                    peroid = peroid + '16-30 ';
                  }
                  peroid = peroid + 'เมษายน ';
                } else if (this.peroidMonth == 5) {
                  if (this.peroidType == 0) {
                    peroid = peroid + '1-15 ';
                  } else if (this.peroidType == 1) {
                    peroid = peroid + '16-31 ';
                  }
                  peroid = peroid + 'พฤษภาคม ';
                } else if (this.peroidMonth == 6) {
                  if (this.peroidType == 0) {
                    peroid = peroid + '1-15 ';
                  } else if (this.peroidType == 1) {
                    peroid = peroid + '16-30 ';
                  }
                  peroid = peroid + 'มิถุนายน ';
                } else if (this.peroidMonth == 7) {
                  if (this.peroidType == 0) {
                    peroid = peroid + '1-15 ';
                  } else if (this.peroidType == 1) {
                    peroid = peroid + '16-31 ';
                  }
                  peroid = peroid + 'กรกฎาคม ';
                } else if (this.peroidMonth == 8) {
                  if (this.peroidType == 0) {
                    peroid = peroid + '1-15 ';
                  } else if (this.peroidType == 1) {
                    peroid = peroid + '16-31 ';
                  }
                  peroid = peroid + 'สิงหาคม ';
                } else if (this.peroidMonth == 9) {
                  if (this.peroidType == 0) {
                    peroid = peroid + '1-15 ';
                  } else if (this.peroidType == 1) {
                    peroid = peroid + '16-30 ';
                  }
                  peroid = peroid + 'กันยายน ';
                } else if (this.peroidMonth == 10) {
                  if (this.peroidType == 0) {
                    peroid = peroid + '1-15 ';
                  } else if (this.peroidType == 1) {
                    peroid = peroid + '16-30 ';
                  }
                  peroid = peroid + 'ตุลาคม ';
                } else if (this.peroidMonth == 11) {
                  if (this.peroidType == 0) {
                    peroid = peroid + '1-15 ';
                  } else if (this.peroidType == 1) {
                    peroid = peroid + '16-30 ';
                  }
                  peroid = peroid + 'พฤศจิกายน ';
                } else if (this.peroidMonth == 12) {
                  if (this.peroidType == 0) {
                    peroid = peroid + '1-15 ';
                  } else if (this.peroidType == 1) {
                    peroid = peroid + '16-30 ';
                  }
                  peroid = peroid + 'ธันวาคม ';
                }
                peroid = peroid + this.searchForm.get('year').value;
                
                let deductExtendList002 = [];
                let deductExtendList003 = [];
                let deductExtendList004 = [];
                let deductExtendList005 = [];
                if(this.checkPointModel.customerList[i].employeeList[j]['deductList']){
                   
                for (let x = 0; x < this.checkPointModel.customerList[i].employeeList[j]['deductList'].length; x++) {
                  if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].code == '002') {
                    if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList) {
                      deductExtendList002 = this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList;
                    }
                  }
                } 
                for (let x = 0; x < this.checkPointModel.customerList[i].employeeList[j]['deductList'].length; x++) {
                  if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].code == '003') {
                    if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList) {
                      deductExtendList003 = this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList;
                    }
                  }
                }
     
                for (let x = 0; x < this.checkPointModel.customerList[i].employeeList[j]['deductList'].length; x++) {
                  if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].code == '004') {
                    if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList) {
                      deductExtendList004 = this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList;
                    }
                  }
                }
     
                for (let x = 0; x < this.checkPointModel.customerList[i].employeeList[j]['deductList'].length; x++) {
                  if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].code == '005') {
                    if (this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList) {
                      deductExtendList005 = this.checkPointModel.customerList[i].employeeList[j]['deductList'][x].deductExtendList;
                    }
                  }
                }
                } 
    
                let workList = [];
                /*let dateStart = 1;
                let dateEnd = 15;
                if (this.peroidType == 1) {
                  dateStart = 16;
                  dateEnd = 31;
                }*/
                for (let m = 0; m < this.checkPointModel.customerList[i].employeeList[j].workList.length; m++) {
                  let workItem = this.checkPointModel.customerList[i].employeeList[j].workList[m];
                  if (workItem.date >= dateStart && workItem.date <= dateEnd) {
                    if(workItem.status == 'W' || workItem.status == 'AAD' || workItem.status == 'AAN'){
                    workList.push(workItem);
                    } 
                  }
                }
                //get status HE ช่วย
                let heList = [];
                for (let ii = 0; ii < this.checkPointModelCompare.customerList.length; ii++) {
                  for (let jj = 0; jj < this.checkPointModelCompare.customerList[ii].employeeList.length; jj++) {
                    if (this.emp.id == this.checkPointModelCompare.customerList[ii].employeeList[jj].employeeId) {
                      for (let mm = 0; mm < this.checkPointModelCompare.customerList[ii].employeeList[jj].workList.length; mm++) {
                        let workItem = this.checkPointModelCompare.customerList[ii].employeeList[jj].workList[mm];
                        if (workItem.date >= dateStart && workItem.date <= dateEnd) {
                          if (workItem.status == 'HE') {
                            heList.push(workItem);
                          }
                        }
                      }
                    }
                  }
                }
                console.log(heList)
                for (let ii = 0; ii < workList.length; ii++) {
                  if (workList[ii].status != 'W' || (workList[ii].status == 'W' && !workList[ii].day && !workList[ii].nigth)) {
                    let workMatch = heList.filter(he => he.date == workList[ii].date);
                    if (workMatch.length > 0) {
                      workList[ii] = workMatch[0];
                    }
                  }
                } 
                console.log(workList)
                if(workList.length>0){
                  let currentYear = new Date().getFullYear();
                  let request = {
                    "customerName": this.checkPointModel.customerList[i].customerName,
                    "employeeName": this.checkPointModel.customerList[i].employeeList[j].firstName
                      + " " + this.checkPointModel.customerList[i].employeeList[j].lastName,
                    "incomeList": this.checkPointModel.customerList[i].employeeList[j].incomeList,
                    "deductList": this.checkPointModel.customerList[i].employeeList[j].deductList,
                    "totalIncome": this.checkPointModel.customerList[i].employeeList[j].totalIncome,
                    "totalDeduct": this.checkPointModel.customerList[i].employeeList[j].totalDeduct,
                    "peroid": peroid,
                    "deductExtendList002": deductExtendList002,
                    "deductExtendList003": deductExtendList003,
                    "deductExtendList004": deductExtendList004,
                    "deductExtendList005": deductExtendList005,
                    "workList": workList,
                    "employeeId": this.checkPointModel.customerList[i].employeeList[j].employeeId
                    //"employeeId": this.checkPointModel.customerList[i].employeeList[j].employeeId
                    //+"_"+this.peroidType+"_"+this.peroidMonth+"_"+currentYear
                  };
                  console.log(request);
                  const parentThis = this;
                  if(status == 'view'){
                    this.slipService.cashSlipImageMonthly(request).subscribe(res => {
                      console.log(res)
                      console.log(res.data);
                      /*let printUrl = this.constant.API_ENDPOINT 
                      + "/slip/download/monthly/"+this.checkPointModel.customerList[i].employeeList[j].employeeId
                      +"_"+this.peroidType+"_"+this.peroidMonth+"_"+currentYear;*/
                      let printUrl = this.constant.API_ENDPOINT
                        + "/slip/download/monthly/" + this.checkPointModel.customerList[i].employeeList[j].employeeId;
                      // setTimeout(function() { 
                      //   parentThis.spinner.hide();
                      //   window.open(printUrl);
                      // }, 30000); 
                      parentThis.spinner.hide();
                      window.open(res.data);
                    }, error => {
                      parentThis.spinner.hide();
                      console.error(error);
                      this.failDialog('');
                    });
                  }else{
                    this.slipService.cashSlipMonthly(request).subscribe(res => {
                      console.log(res)
                      console.log(res.data);
                      /*let printUrl = this.constant.API_ENDPOINT 
                      + "/slip/download/monthly/"+this.checkPointModel.customerList[i].employeeList[j].employeeId
                      +"_"+this.peroidType+"_"+this.peroidMonth+"_"+currentYear;*/
                      let printUrl = this.constant.API_ENDPOINT
                        + "/slip/download/monthly/" + this.checkPointModel.customerList[i].employeeList[j].employeeId;
                      // setTimeout(function() { 
                      //   parentThis.spinner.hide();
                      //   window.open(printUrl);
                      // }, 30000); 
                      parentThis.spinner.hide();
                      window.open(res.data);
                    }, error => {
                      parentThis.spinner.hide();
                      console.error(error);
                      this.failDialog('');
                    });
                  }
                  
              }
             } 
          }//End loop Check Employee
        }
      //}
    }
  }

  printSlip(){
    console.log('print slip')
    this.spinner.show();
    //ประกันสังคม
    let sumSociety = 0.00;
    //customer name
    let customerName = '';
    if(this.workViewList.length>0){
      for(let i=0;i<this.workViewList.length;i++){
        if(this.workViewList[i].cust.length>0){
          customerName = this.workViewList[i].cust[0].customerName;
          break;
        }
      }
      //console.log(this.workViewList[0])
      //console.log(this.workViewList[0].cust[0])
      //customerName = this.workViewList[0].cust[0].customerName;
    }
    //total income - deduct
    let totalIncome = 0.00;
    let totalDeduct = 0.00;
    // ----------------- income list -----------------
    let incomeList = [];
    //001 and 002
    let obj001 = {'code':'001','name':'ค่าแรง(D)','quantity':0,'price':0.00,'total':0.00,'editType':0,'incomeType':'FIX'};
    let obj002 = {'code':'002','name':'ค่าแรง(N)','quantity':0,'price':0.00,'total':0.00,'editType':0,'incomeType':'FIX'};
    let countDay = 0;
    let priceDay = 550;
    let totalDay = 0.00;
    let countNigth = 0;
    let priceNigth = 550;
    let totalNigth = 0.00;
    this.workViewList.forEach(item => {
      console.log(item)
      for(let i=0;i<item.cust.length;i++){
        if(item.cust[i].work.status == 'W' && item.cust[i].work.day){
          countDay++;
          priceDay = Number(item.cust[i].work.waged);
          totalDay = totalDay+priceDay;
          sumSociety = sumSociety + ((priceDay/12)*8);
        }
        if(item.cust[i].work.status == 'W' && item.cust[i].work.nigth){
          countNigth++;
          priceNigth = Number(item.cust[i].work.wagen); 
          totalNigth = totalNigth+priceNigth;
          sumSociety = sumSociety + ((priceNigth/12)*8);
        }
      }
    });
    obj001.quantity = countDay;
    obj001.price = priceDay;
    obj001.total = totalDay;
    incomeList.push(obj001);
    obj002.quantity = countNigth;
    obj002.price = priceNigth;
    obj002.total = totalNigth;
    incomeList.push(obj002);
    console.log(incomeList);
    totalIncome = totalIncome + totalDay + totalNigth;
    console.log(totalIncome);
    //End 001 and 002

    //-----------------End income -----------------


    //----------------- work list -----------------
    let workList = [];
    this.workViewList.forEach(item => {
      if(item.cust.length>0){
        for(let i=0;i<item.cust.length;i++){
          workList.push(item.cust[i].work)
        }
      }else{
        workList.push({
          'date':item.day,
          'status':''
        })
      }
    });
    console.log(workList);
    // ----------------- End work list -----------------

    // ----------------- deduct list -----------------
    let deductList = [];

    //001 ประกันสังคม
    let societyPercentText = '5';
    let societyPercent = 0.05;
    let summarySociety = 0.00;
    console.log('sumSociety:' + sumSociety)
    summarySociety = Math.ceil((sumSociety) * (societyPercent));
    console.log('summarySociety:' + summarySociety)
    totalDeduct = totalDeduct + summarySociety;
    deductList.push({'code':'001','name':'ประกันสังคม('+societyPercentText+'%)','quantity':1,'price':0,'total':summarySociety,'editType':0,'incomeType':'MANUAL'});
    
    //005 เบิกล่วงหน้า  *** ต้องไปอ่านค่าจากหน้า manange การเบิก
    let deductExtendList05 = [];
    let priceExtend05 = 0.00;
    let totalExtend05 = 0.00;
    console.log(this.advList);
    if(!this.isEmpty(this.advList)){
      this.advList.forEach(item => {
        for(let i=0;i<item.items.length;i++){
          let advItem = item.items[i];
          let splitPeroid = advItem.paid_peroid.split('_');
          let splitMonth = splitPeroid[0].split('-');
          if(Number(splitMonth[0]) == this.peroidMonth 
          && Number(splitMonth[1]) == this.peroidType
          && Number(splitPeroid[1]) == Number(this.searchForm.get('year').value)){
            deductExtendList05.push({'desc':'เบิก '+this.removeTimeOfDate(item.pick_date), 'amount':advItem.amount});
            priceExtend05 = advItem.amount;
            totalExtend05 = totalExtend05 + advItem.amount;
            totalDeduct = totalDeduct + advItem.amount;
          }
        }
      });
    }
    deductList.push({'code':'005','name':'เบิกล่วงหน้า','quantity':1,'price':priceExtend05,'total':totalExtend05,'deductExtendList':deductExtendList05,'editType':0,'incomeType':'FIX'});
    console.log(deductList);
    // ----------------- End  deduct list -----------------

    //return;

    let request = {
      "customerName":customerName,
      "employeeName":this.emp.first_name
      +" "+this.emp.last_name,
      "incomeList":incomeList,
      "deductList":deductList,
      "totalIncome":totalIncome,
      "totalDeduct":totalDeduct,
      "peroid": this.getPeriod(),
      "deductExtendList002": [],
      "deductExtendList003": [],
      "deductExtendList004": [],
      "deductExtendList005": deductExtendList05,
      "workList": workList,
      "employeeId": this.emp.id
    };
    console.log(request);
    /*
    this.slipService.cashSlipMonthly(request).subscribe(res=>{
      console.log(res)
      console.log(res.data);
      this.spinner.hide();
      window.open(this.constant.API_ENDPOINT + "/slip/download/monthly/"+this.emp.id);
    });*/
    const parentThis = this;
    this.slipService.cashSlipMonthly(request).subscribe(res => {
      console.log(res)
      console.log(res.data);
      let printUrl = this.constant.API_ENDPOINT
        + "/slip/download/monthly/" + this.emp.id;
      parentThis.spinner.hide();
      window.open(res.data);
    });
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
  
    return true
  }


  getPeriod(){
    let peroid = '';
    if(this.peroidMonth == 1){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-31 ';
      }
      peroid = peroid + 'มกราคม ';
    }else if(this.peroidMonth == 2){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-29 ';
      }
      peroid = peroid + 'กุมภาพันธ์ ';
    }else if(this.peroidMonth == 3){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-31 ';
      }
      peroid = peroid + 'มีนาคม ';
    }else if(this.peroidMonth == 4){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-30 ';
      }
      peroid = peroid + 'เมษายน ';
    }else if(this.peroidMonth == 5){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-31 ';
      }
      peroid = peroid + 'พฤษภาคม ';
    }else if(this.peroidMonth == 6){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-30 ';
      }
      peroid = peroid + 'มิถุนายน ';
    }else if(this.peroidMonth == 7){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-31 ';
      }
      peroid = peroid + 'กรกฎาคม ';
    }else if(this.peroidMonth == 8){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-31 ';
      }
      peroid = peroid + 'สิงหาคม ';
    }else if(this.peroidMonth == 9){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-30 ';
      }
      peroid = peroid + 'กันยายน ';
    }else if(this.peroidMonth == 10){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-30 ';
      }
      peroid = peroid + 'ตุลาคม ';
    }else if(this.peroidMonth == 11){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-30 ';
      }
      peroid = peroid + 'พฤศจิกายน ';
    }else if(this.peroidMonth == 12){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-30 ';
      }
      peroid = peroid + 'ธันวาคม ';
    }
    peroid = peroid + this.searchForm.get('year').value;
    return peroid;
  }

  selectedDate(value: any, datepicker?: any) {
    this.startDate = value.start._d;
    this.startDateStr = this.getDateStr(this.startDate);
    console.log(this.startDateStr);
    this.endDate = value.end._d;
    this.endDateStr = this.getDateStr(this.endDate);
    console.log(this.endDateStr);
  }

  getDateStr(date){
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    return dd + '-' + mm + '-' + yyyy;
  }

  removeTimeOfDate(dateString){
    let split = dateString.split(' ');
    return split[0];
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WarantyInsuranceComponent } from './warantyInsurance.component';
import { CommingsoonModule } from 'src/app/common-components/comming-soon/comming-soon.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommingsoonModule
  ],
  declarations: [WarantyInsuranceComponent],
  exports: [
    WarantyInsuranceComponent
  ],
  providers: [
  ]
})
export class WarantyInsuranceModule { }
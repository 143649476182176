import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-reserveLoan ',
  templateUrl: './reserveLoan.component.html',
  styleUrls: ['./reserveLoan.component.css']
})
export class ReserveLoanComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  } 

  getReserveLoanHistory(){
    $('#modal-reserve-loan-history').modal('show');
  }
}

import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-sickLeave',
  templateUrl: './sickLeave.component.html',
  styleUrls: ['./sickLeave.component.css']
})
export class SickLeaveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  } 

}

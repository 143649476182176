import { Component, OnInit ,ViewChild, ElementRef, NgZone} from '@angular/core';
import { MapsAPILoader } from '@agm/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeaveService } from '../../shared';
import { NgxSpinnerService } from "ngx-spinner";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.css']
})
export class LeaveComponent implements OnInit {


  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private leaveService: LeaveService,
  ) { 

  }

  personnel;
  employeeId;
  ngOnInit() { 
    this.personnel = JSON.parse(localStorage.getItem('tisToken'));
    if(this.personnel){
      this.employeeId = this.personnel.id;
      this.leaveSearch();
    }
  } 

  displayedColumns: string[] = ['leaveTypeName', 'leaveDate', 'useLeaveDay', 'institution', 'details', 'url1'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  leaveActiveDate = '';
  slNumberLeaveDay = '-';
  slUseLeaveDay = '-';
  slBalanceLeaveDay = '-';
  vlNumberLeaveDay = '-';
  vlUseLeaveDay = '-';
  vlBalanceLeaveDay = '-';
  blNumberLeaveDay = '-';
  blUseLeaveDay = '-';
  blBalanceLeaveDay = '-';
  employeeFullName = '';
  input;
  leaveSearch(){
    this.spinner.show();
    this.leaveActiveDate = '';
    this.slNumberLeaveDay = '-';
    this.slUseLeaveDay = '-';
    this.slBalanceLeaveDay = '-';
    this.vlNumberLeaveDay = '-';
    this.vlUseLeaveDay = '-';
    this.vlBalanceLeaveDay = '-';
    this.blNumberLeaveDay = '-';
    this.blUseLeaveDay = '-';
    this.blBalanceLeaveDay = '-';
    this.employeeFullName = '';
    var request = {};
    request["employeeId"] = this.employeeId;

    console.log(request);
    $("#employe_table").DataTable().clear().destroy();
    this.leaveService.leaveSearch(request).subscribe(data => {
        console.log(data);
        if(data.employeeFullName && data.employeeCode){
          this.employeeFullName = data.employeeFullName +" "+ data.employeeCode;
          this.leaveActiveDate = data.leaveActiveDate;
          this.slNumberLeaveDay = data.slNumberLeaveDay;
          this.slUseLeaveDay = data.slUseLeaveDay;
          this.slBalanceLeaveDay = data.slBalanceLeaveDay;
          this.vlNumberLeaveDay = data.vlNumberLeaveDay;
          this.vlUseLeaveDay = data.vlUseLeaveDay;
          this.vlBalanceLeaveDay = data.vlBalanceLeaveDay;
          this.blNumberLeaveDay = data.blNumberLeaveDay;
          this.blUseLeaveDay = data.blUseLeaveDay;
          this.blBalanceLeaveDay = data.blBalanceLeaveDay;
        }else{
          this.employeeFullName = 'ยังไม่มีสิทธิ์การลา';
        }

        this.dataSource = new MatTableDataSource(data.leaveHistoryList);
        if(this.dataSource.paginator && this.dataSource.sort){
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }

        // this.employeeList.forEach(item=>{
        //   item['start_work'] = this.commonService.getDateThNotimeYYYYMMDD2(item['start_work']);
        // });
        // console.log(this.employeeList);

        setTimeout(() => {
          this.spinner.hide();
          $('#employe_table').DataTable({
            "columnDefs": [ {
              "targets": 'no-sort',
              "orderable": false,
        } ]
          });
        }, 100);

    }, error => {
      this.spinner.hide();
      console.error(error);
    });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
    //setTimeout(() => {location.reload();}, 1000);
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'ไม่สามารถทำรายการได้',
      text: msg
    })
  }

  warningDialog(msg){
    Swal.fire({
      type: 'warning',
      title: 'ไม่สามารถทำรายการได้',
      text: msg
    })
  }

}
